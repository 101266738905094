/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
    padding: 2.4rem 0;
    font-size: 1.4rem;
    font-family: var(--kp-font-mono);
}

#footer .copyright {
    text-align: center;
}

#footer .credits {
    padding-top: 0.8rem;
    text-align: center;
    font-size: 1.4rem;
}
