@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
    --kp-font-sans: "Source Sans", "Roboto", -apple-system, system-ui, sans-serif;
    --kp-font-mono: "Fira Code", "Source Code Pro", monospace;
}

html {
    font-size: 10px;
}

@font-face {
    font-family: "Material Icons Outlined";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsoutlined/v108/gok-H7zzDkdnRel8-DQ6KAXJ69wP1tGnf4ZGhUce.woff2)
        format("woff2");
}

@font-face {
    font-family: "Material Icons Round";
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialiconsround/v107/LDItaoyNOAY6Uewc665JcIzCKsKc_M9flwmP.woff2)
        format("woff2");
}

.material-icons-round {
    font-family: "Material Icons Round";
    font-weight: normal;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}
.material-icons-outlined {
    font-family: "Material Icons Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: var(--kp-font-sans);
    color: var(--kp-font-color);
    margin-bottom: 0.8rem;
}

p {
    font-family: var(--kp-font-sans);
    color: var(--kp-font-color);
    font-size: clamp(1.4rem, 5vw, 1.6rem);
    line-height: 1.6;
}

span,
em,
div {
    color: var(--kp-font-color);
}

a {
    text-decoration: none;
    font-family: var(--kp-font-mono);
    color: var(--kp-font-color);
}

.link {
    display: inline-block;
    transition: var(--transition);
    text-decoration-skip-ink: auto;
}

a:hover,
a:active {
    text-decoration: none;
    font-family: var(--kp-font-mono);
    color: var(--kp-color-primary);
}

a.link:hover::after
/* a:focus::after,
a:active::after  */ {
    width: 100%;
}

a.link::after {
    content: "";
    display: block;
    width: 0;
    height: 0.1rem;
    position: relative;
    bottom: 0.2rem;
    background-color: var(--kp-color-primary);
    transition: var(--transition);
    opacity: 0.5;
}
