main {
    min-height: 100vh;
    height: 100%;
    width: 100%;
    /* overflow-x: hidden; */
    /* max-width: 160rem; */
    margin: 0 auto;
    padding: 0 14.4rem;
}

@media (max-width: 1080px) {
    main {
        padding: 0 9.6rem;
    }
}

@media (max-width: 768px) {
    main {
        padding: 0 4.8rem;
    }
}

@media (max-width: 480px) {
    main {
        padding: 0 2.4rem;
    }
}
