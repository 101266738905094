/*--------------------------------------------------------------
# Work
--------------------------------------------------------------*/
section {
    margin: 0 auto;
    padding: 9.6rem 0;
}

#work .project-list {
    list-style: none;
    padding: 0;
    margin: 0;
}

#work .project-list .project-item {
    position: relative;
    display: grid;
    gap: 0.8rem;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: center;
    align-items: center;
}

#work .project-item:not(:last-of-type) {
    margin-bottom: 9.6rem;
}

#work .project-item .project-content {
    position: relative;
    grid-area: 1 / 1 / -1 / 7;
}

#work .project-item .project-overline {
    margin: 0.8rem 0;
    color: var(--kp-color-secondary);
    font-family: var(--kp-font-mono);
    font-size: var(--fz-xs);
    font-weight: 400;
}

#work .project-item .project-title {
    color: var(--kp-font-color);
    font-size: clamp(2.4rem, 5vw, 2.8rem);
}

#work .project-item .project-description {
    box-shadow: 0 1rem 3rem -1.6rem var(--navy-shadow);
    transition: var(--transition);
    position: relative;
    z-index: 2;
    padding: 2.4rem;
    border-radius: var(--border-radius);
    background-color: var(--kp-background-color);
}

#work .project-item .project-description:hover,
#work .project-item .project-description:focus,
#work .project-item .project-image:hover,
#work .project-item .project-image:focus {
    box-shadow: 0 2rem 3rem -1.6rem var(--navy-shadow);
}

#work .project-item .project-tech-list,
#demos .grid-item .project-tech-list {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 2;
    margin: 2.4rem 0 0.8rem;
    padding: 0;
    list-style: none;
}

#work .project-item .project-tech-list li,
#demos .grid-item .project-tech-list li {
    margin: 0 1.6rem 0.8rem 0;
    font-family: var(--kp-font-mono);
    font-size: var(--fz-xs);
    white-space: nowrap;
    background: rgba(var(--kp-color-secondary-rgb), 0.16);
    border-radius: var(--border-radius);
    padding: 0.4rem 0.8rem;
}

#work .project-item .project-links {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin-top: 0.8rem;
    margin-left: -0.8rem;
}

#work .project-item .project-links a {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 0.8rem;
}

#work .project-item .project-image {
    border-radius: var(--border-radius);
    box-shadow: 0 1rem 3rem -1.6rem var(--navy-shadow);
    transition: var(--transition);
    grid-area: 1 / 6 / -1 / -1;
    position: relative;
    z-index: 1;
}

#work .project-item .project-image a {
    width: 100%;
    height: 100%;
}

#work .project-item .project-image a .image-wrapper {
    width: 100%;
    height: 100%;
    background-color: var(--kp-color-primary);
    border-radius: var(--border-radius);
    vertical-align: middle;
    overflow: hidden;
}

#work .project-item .project-image a .image-wrapper::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
    z-index: 3;
    border-radius: var(--border-radius);
    transition: var(--transition);
    background-color: var(--kp-color-primary);
    mix-blend-mode: overlay;
}

#work .project-item .project-image a .image-wrapper:hover,
#work .project-item .project-image a .image-wrapper:focus {
    background: transparent;
    outline: 0;
}

#work .project-item .project-image a .image-wrapper img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    filter: grayscale(100%) contrast(1) brightness(0.5);
    mix-blend-mode: multiply;
}

#work .project-item .project-image a .image-wrapper:hover::before,
#work .project-item .project-image a .image-wrapper:focus::before,
#work .project-item .project-image a .image-wrapper:hover img,
#work .project-item .project-image a .image-wrapper:focus img {
    background: transparent;
    filter: none;
}

#work .project-item:nth-of-type(2n + 1) .project-content {
    grid-column: 7 / -1;
    text-align: right;
}

#work .project-item:nth-of-type(2n + 1) .project-tech-list {
    -webkit-box-pack: end;
    justify-content: flex-end;
}

#work .project-item:nth-of-type(2n + 1) .project-links {
    -webkit-box-pack: end;
    justify-content: flex-end;
    margin-left: 0;
    margin-right: -0.8rem;
}

#work .project-item:nth-of-type(2n + 1) .project-image {
    grid-column: 1 / 8;
}

#demos .demos-grid {
    list-style: none;
    padding: 0;
    list-style: none;
    padding: 0;
    margin: 4.8rem 0 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1.6rem;
    position: relative;
}

#demos .demos-grid .grid-item {
    padding: 24px 20px;
    height: 100%;
    /* position: relative; */
    /* overflow: hidden; */
    background: var(--kp-background-color);
    box-shadow: 0 0 29px 0 rgba(var(--kp-color-medium-rgb), 0.2);
    /* transition: all 0.3s ease-in-out; */
    /* text-align: center; */
    border-radius: 8px;
    z-index: 1;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#demos .grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0;
    transition: ease-in-out 0.3s;
    z-index: -1;
}

.dark-theme #demos .grid-item {
    box-shadow: 0 0 29px 0 rgba(var(--kp-color-light-rgb), 0.2);
}

#demos .grid-item .grid-icon {
    text-align: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    color: var(--kp-color-primary);
    transition: all 0.3s ease-in-out;
}

#demos .grid-item .grid-icon i {
    font-size: 36px;
    line-height: 1;
}

#demos .grid-item .demo-title {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 18px;
}

#demos .grid-item .demo-description {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 0;
}

#demos .grid-item .grid-top {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    align-items: center;
    margin-bottom: 32px;
}

#demos .grid-item .demo-links {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: -10px;
    color: var(--light-slate);
}
#demos .grid-item .demo-links a {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    padding: 5px 7px;
    color: var(--kp-font-color);
    z-index: 9;
}

#demos .grid-item .demo-title a {
    color: var(--kp-font-color);
    cursor: pointer;
}
#demos .grid-item .demo-title:hover a,
#demos .grid-item .demo-links a:hover {
    color: var(--kp-color-primary);
}

#demos .grid-item .demo-title a::before {
    content: "";
    display: block;
    position: absolute;
    z-index: 0;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}

@media (min-width: 768px) {
    #work .project-item .project-title {
        margin: 0 0 20px;
        color: var(--white);
    }
}

@media (max-width: 1080px) {
    #work .project-item .project-content {
        grid-column: 1 / 9;
    }

    #work .project-item:nth-of-type(2n + 1) .project-content {
        grid-column: 5 / -1;
    }
}

@media (max-width: 768px) {
    #work .project-item .project-content {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        height: 100%;
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        z-index: 5;
        background: rgba(var(--kp-color-light-rgb), 0.47);
        border-radius: var(--border-radius);
    }

    #work .project-item .project-description {
        padding: 20px 0px;
        background-color: transparent;
        box-shadow: none !important;
    }

    #work .project-item .project-tech-list {
        margin: 10px 0px;
    }

    #work .project-item .project-image {
        grid-column: 1 / -1;
        height: 100%;
        opacity: 0.25;
    }

    #work .project-item .project-image a .image-wrapper img {
        object-fit: cover;
    }

    #work .project-item:nth-of-type(2n + 1) .project-content {
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        text-align: left;
    }

    #work .project-item:nth-of-type(2n + 1) .project-tech-list {
        -webkit-box-pack: start;
        justify-content: flex-start;
    }

    #work .project-item:nth-of-type(2n + 1) .project-links {
        -webkit-box-pack: start;
        justify-content: flex-start;
        margin-left: -10px;
        margin-right: 0px;
    }

    #work .project-item:nth-of-type(2n + 1) .project-image {
        grid-column: 1 / -1;
    }

    .demos .grid-item::before {
        bottom: 60%;
    }
}

@media (prefers-reduced-motion: no-preference) {
    .grid-item:hover,
    .grid-item:focus-within {
        transform: translateY(-7px);
    }
}

@media (max-width: 991px) {
    #demos .demos-grid {
        grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
    }
}

@media (max-width: 667px) {
    #demos .demos-grid {
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
    }
}
