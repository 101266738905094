/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
.section-bg {
    background: linear-gradient(180deg, #f2f6f9 0%, #fff 100%);
}

.section-title {
    padding-bottom: 1.6rem;
    position: relative;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin: 0.8rem 0 3.6rem;
    width: 100%;
    font-size: clamp(2.4rem, 5vw, 3.2rem);
}

.section-subtitle {
    font-size: clamp(2rem, 5vw, 2.8rem);
    text-align: center;
    margin-bottom: 0.8rem;
}

.section-title::before {
    position: relative;
    counter-increment: section 1;
    content: "0" counter(section) ".";
    margin-right: 0.8rem;
    top: 0.4rem;
    color: var(--kp-color-secondary);
    font-family: var(--kp-font-mono);
    font-size: clamp(1.8rem, 3vw, 2.4rem);
    font-weight: 400;
}

.section-title::after {
    content: "";
    display: block;
    position: relative;
    width: 30rem;
    height: 0.2rem;
    margin-left: 2rem;
    top: 0.2rem;
    background-color: var(--kp-color-secondary);
}

i.devicon {
    font-size: 2rem;
}
i.bx {
    font-size: 2.4rem;
}
