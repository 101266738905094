.left-div {
    width: 3.6rem;
    position: fixed;
    bottom: 0;
    left: 3.6rem;
    right: auto;
    z-index: 10;
}

.social-links:after {
    content: "";
    display: block;
    width: 0.1rem;
    height: 9.6rem;
    margin: 0 auto;
    background-color: var(--kp-color-dark);
}

.social-links {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
}

.social-links li a {
    line-height: 1;
    padding: 0.8rem;
    display: inline-block;
    text-decoration-skip-ink: auto;
    position: relative;
    transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.social-links li a:hover,
.social-links li a:focus {
    transform: translateY(-0.4rem);
    color: var(--kp-color-primary);
}
.social-links li a svg {
    width: 2rem;
    height: 2rem;
}

svg.feather {
    fill: none;
}

@media (max-width: 1080px) {
    .left-div {
        left: 2rem;
        right: auto;
    }
}

@media (max-width: 768px) {
    .left-div {
        display: none;
    }
}
