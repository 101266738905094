/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
    transition: all 0.6s;
    height: 6.4rem;
    background-color: transparent;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99;
}
#header.header-scrolled {
    box-shadow: 0 0 2rem 0 rgba(0, 0, 0, 0.12);
    background-color: var(--kp-background-color);
}
#header.header-scrolled .logo h1 {
    background-color: rgba(var(--kp-color-secondary-rgb), 0.08);
}

#header .logo h1 {
    font-family: "Source Code Pro", monospace;
    font-size: 2.8rem;
    margin: 0;
    border-radius: 3.2rem;
    line-height: 1;
    line-height: 1;
    font-weight: bold;
}
#header .logo h1 a {
    padding: 0.8rem 1.6rem;
    transform: translateY(-0.3rem);
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Source Code Pro", monospace;
    color: var(--kp-color-primary);
}
#header .logo h1 a span {
    font-family: var(--kp-font-sans);
    font-weight: normal;
    transform: scale(1.4) translateY(0.3rem);
    margin-left: 0.8rem;
    color: var(--kp-color-primary);
    margin-right: 0px;
}
#header .logo h1 a span:nth-child(2) {
    transform: scale(1.4) translateY(0.2rem) rotate(5deg);
    /* transform: scale(1.4) translateY(4px) rotate(5deg); */
}

#header .logo img {
    padding: 0;
    margin: 0;
    max-height: 4rem;
}

/*--------------------------------------------------------------
  # Navigation Menu
  --------------------------------------------------------------*/
/**
  * Desktop Navigation
  */
.navbar {
    padding: 0;
}

.navbar ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
}

.navbar li {
    position: relative;
}

.navbar a,
.navbar a:focus {
    padding: 0.8rem 0 0.8rem 2.4rem;
    font-size: 1.4rem;
    white-space: nowrap;
    transition: 0.3s;
}

.navbar a i,
.navbar a:focus i {
    font-size: 1.2rem;
    line-height: 0;
    margin-left: 0.4rem;
}

.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
    color: var(--kp-color-primary);
}

.navbar .active {
    font-weight: bold;
}

/**
  * Mobile Navigation
  */
.mobile-nav-toggle {
    cursor: pointer;
    display: none;
    transition: 0.5s;
    font-size: 2.4rem;
    width: 2.4rem;
    height: 2.4rem;
    line-height: 1;
}

@media (max-width: 991px) {
    .mobile-nav-toggle {
        display: block;
    }

    .navbar ul {
        display: none;
    }
}

.navbar-mobile {
    position: fixed;
    overflow: hidden;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: rgba(13, 21, 29, 0.6);
    transition: 0.3s;
    z-index: 998;
}

.navbar-mobile .mobile-nav-toggle {
    position: absolute;
    top: 1.6rem;
    right: 1.6rem;
}

.navbar-mobile ul {
    display: block;
    position: absolute;
    top: 5.6rem;
    right: 1.6rem;
    bottom: 1.6rem;
    left: 1.6rem;
    padding: 1.6rem 0;
    border-radius: var(--border-radius);
    background-color: var(--kp-background-color);
    overflow-y: auto;
    transition: 0.3s;
}

.navbar-mobile a,
.navbar-mobile a:focus {
    padding: 0.8rem 1.6rem;
    font-size: 1.6rem;
}

.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
    color: var(--kp-color-primary);
}
