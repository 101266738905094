/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/

#about {
    padding: 7rem 0 10rem 0;
}

#about .about-wrapper {
    padding: 0;
    display: grid;
    grid-template-columns: 4fr 2fr;
    gap: 4.8rem;
}

@media (max-width: 768px) {
    #about .about-wrapper {
        display: block;
    }
    #about .about-wrapper .image {
        margin: 4.8rem auto 0;
        width: 70%;
    }
}

#about .about-wrapper .content {
    padding-right: 3rem;
}

#about .about-wrapper .content h3 {
    font-weight: 500;
    font-size: 3.4rem;
    color: #2c4964;
}

#about .about-wrapper .content p {
    margin: 0 0 1.6rem;
    font-size: 1.6rem;
    font-family: var(--kp-font-sans);
    line-height: 1.4;
}

#about .about-wrapper ul.skills-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(14rem, 20rem));
    gap: 0 0.8rem;
    padding: 0;
    margin: 1.6rem 0 0;
    overflow: hidden;
    list-style: none;
}

#about .about-wrapper .skills-list li {
    position: relative;
    margin-bottom: 0.8rem;
    padding-left: 1.6rem;
    font-family: var(--kp-font-mono);
    font-size: 1.4rem;
}

#about .about-wrapper .skills-list :hover {
    color: var(--kp-color-primary);
}

#about .about-wrapper .skills-list li::before {
    content: "▹";
    position: absolute;
    left: 0px;
    color: var(--kp-color-primary);
    font-size: 1.6rem;
    line-height: 1;
}

#about .about-wrapper .image {
    background-size: cover;
    position: relative;
    width: 30rem;
    height: 30rem;
    box-shadow: 0 10px 30px -15px var(--navy-shadow);
    transition: var(--transition);
    display: block;
    border-radius: var(--border-radius);
    background-color: var(--kp-color-primary);
}

#about .about-wrapper .image::before,
#about .about-wrapper .image::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius);
    transition: var(--transition);
}

#about .about-wrapper .image::after {
    border: 2px solid var(--kp-color-primary);
    top: 20px;
    left: 20px;
    z-index: -1;
}

#about .about-wrapper .image::before {
    top: 0px;
    left: 0px;
    background-color: var(--kp-color-primary);
    mix-blend-mode: overlay;
}

#about .about-wrapper .image:hover,
#about .about-wrapper .image:focus {
    outline: 0px;
    box-shadow: 0 20px 30px -15px var(--navy-shadow);
}

#about .about-wrapper .image:hover::after,
#about .about-wrapper .image:focus::after {
    top: 15px;
    left: 15px;
}

#about .about-wrapper .image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
    border-radius: var(--border-radius);
    mix-blend-mode: multiply;
    filter: grayscale(100%) contrast(1);
    transition: var(--transition);
}

#about .about-wrapper .image:hover img,
#about .about-wrapper .image:focus img {
    filter: none;
    mix-blend-mode: normal;
}

@media (max-width: 991px) {
    #about .about-wrapper .image {
        text-align: center;
        width: 26rem;
        height: 26rem;
    }
}

@media (max-width: 667px) {
    #about .about-wrapper ul.skills-list {
        grid-template-columns: repeat(1, minmax(14rem, 20rem));
    }
    #about .about-wrapper .image {
        width: 22rem;
        height: 22rem;
    }
    #about .about-wrapper .image img {
        max-width: 100%;
    }
}
