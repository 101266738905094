/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
    background: url("../../../public/assets/images/me/one.png") top right;
    background-size: contain;
    background-repeat: no-repeat;
    min-height: 50rem;
    height: 100vh;
    padding: 0;
}

#hero .hero-wrapper {
    background: rgba(var(--kp-color-light-rgb), 0.5);
    height: 100%;
}

#hero .container {
    height: 100%;
    width: 100%;
    margin: 0px auto;
    padding: 0 14.4rem;
    max-width: 160rem;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    flex-direction: column;
    align-items: flex-start;
}

#hero h1.heading,
#hero h1.heading * {
    font-family: var(--kp-font-mono);
    color: var(--kp-color-primary);
    margin: 0 0 1.6rem 0.4rem;
    font-size: clamp(1.4rem, 5vw, 1.6rem);
    font-weight: 600;
}

#hero h2.name {
    font-family: var(--kp-font-sans);
    font-weight: bold;
    font-size: clamp(4rem, 8vw, 7rem);
    color: var(--kp-font-color);
    line-height: 1.1;
}

#hero h3.tag {
    font-family: var(--kp-font-sans);
    font-weight: bold;
    color: var(--kp-color-dark-tint);
    font-size: clamp(3rem, 8vw, 6rem);
    line-height: 1.1;
}

.dark-theme #hero h3.tag {
    color: var(--kp-color-dark-shade);
}

#hero p.description {
    margin: 1.6rem 0 0;
    max-width: 64rem;
    font-size: 1.6rem;
}

#hero .btn-get-started {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 4.8rem;
    text-align: center;
    font-size: 8rem;
    cursor: pointer;
    transition: 0.5s;
    transition: 0.5s;
}

#hero .btn-get-started i {
    transition: 0.5s;
    color: var(--kp-color-dark);
    font-size: inherit;
}

#hero .btn-get-started:hover {
    color: var(--kp-color-primary);
}

#hero .btn-get-started:hover i {
    transform: translateY(1.6rem);
    color: var(--kp-color-primary);
}

@media (max-width: 768px) {
    #hero .btn-get-started {
        font-size: 6.4rem;
        bottom: 3.2rem;
    }
}

@media (max-width: 540px) {
    #hero .btn-get-started {
        font-size: 4.8rem;
        bottom: 1.6rem;
    }
}

@media (min-width: 1024px) {
    #hero {
        background-attachment: fixed;
    }
}

@media (max-width: 480px) {
    #hero {
        background-position: top center;
        background-size: cover;
    }
    #hero .hero-wrapper {
        background: rgba(var(--kp-color-light-rgb), 0.7);
    }
}

@media (max-width: 1080px) {
    #hero .container {
        padding: 0 9.6rem;
    }
}

@media (max-width: 768px) {
    #hero .container {
        padding: 0 4.8rem;
    }
}

@media (max-width: 480px) {
    #hero .container {
        padding: 0 2.4rem;
    }
}
