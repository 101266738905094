:root {
    /** primary **/
    /* --kp-color-primary: #ff5e95; */
    --kp-color-primary: #80deea;
    /* --kp-color-primary-rgb: 255, 94, 149; */
    --kp-color-primary-rgb: 128, 222, 234;
    --kp-color-primary-contrast: #ffffff;
    --kp-color-primary-contrast-rgb: 255, 255, 255;
    --kp-color-primary-shade: #73c8d3;
    --kp-color-primary-tint: #8de1ec;
    --kp-color-white: #fff;
    --kp-background-color: #fff;
    /** secondary **/
    /* --kp-color-secondary: #ff8e8e; */
    --kp-color-secondary: #d7f580;
    /* --kp-color-secondary-rgb: 255, 142, 142; */
    --kp-color-secondary-rgb: 215, 245, 128;
    --kp-color-secondary-contrast: #ffffff;
    --kp-color-secondary-contrast-rgb: 255, 255, 255;
    --kp-color-secondary-shade: #c2dd73;
    --kp-color-secondary-tint: #dbf68d;
    /** tertiary **/
    --kp-color-tertiary: #58abae;
    --kp-color-tertiary-rgb: 88, 171, 174;
    --kp-color-tertiary-contrast: #ffffff;
    --kp-color-tertiary-contrast-rgb: 255, 255, 255;
    --kp-color-tertiary-shade: #4f9a9d;
    --kp-color-tertiary-tint: #69b3b6;

    /** success **/
    --kp-color-success: #2dd36f;
    --kp-color-success-rgb: 45, 211, 111;
    --kp-color-success-contrast: #ffffff;
    --kp-color-success-contrast-rgb: 255, 255, 255;
    --kp-color-success-shade: #28ba62;
    --kp-color-success-tint: #42d77d;

    /** warning **/
    --kp-color-warning: #ffc409;
    --kp-color-warning-rgb: 255, 196, 9;
    --kp-color-warning-contrast: #000000;
    --kp-color-warning-contrast-rgb: 0, 0, 0;
    --kp-color-warning-shade: #e0ac08;
    --kp-color-warning-tint: #ffca22;

    /** danger **/
    --kp-color-danger: #eb445a;
    --kp-color-danger-rgb: 235, 68, 90;
    --kp-color-danger-contrast: #ffffff;
    --kp-color-danger-contrast-rgb: 255, 255, 255;
    --kp-color-danger-shade: #cf3c4f;
    --kp-color-danger-tint: #ed576b;

    /** dark **/
    --kp-color-dark: #383a3e;
    --kp-color-dark-rgb: 34, 36, 40;
    --kp-color-dark-contrast: #ffffff;
    --kp-color-dark-contrast-rgb: 255, 255, 255;
    --kp-color-dark-shade: #323438;
    --kp-color-dark-tint: #4c4e51;

    /** medium **/
    --kp-color-medium: #92949c;
    --kp-color-medium-rgb: 146, 148, 156;
    --kp-color-medium-contrast: #ffffff;
    --kp-color-medium-contrast-rgb: 255, 255, 255;
    --kp-color-medium-shade: #808289;
    --kp-color-medium-tint: #9d9fa6;

    /** light **/
    --kp-color-light: #f4f5f8;
    --kp-color-light-rgb: 244, 245, 248;
    --kp-color-light-contrast: #000000;
    --kp-color-light-contrast-rgb: 0, 0, 0;
    --kp-color-light-shade: #d7d8da;
    --kp-color-light-tint: #f5f6f9;

    --kp-item-background: transparent;
    --kp-toolbar-background: var(--kp-color-white, #fff);
    --kp-tab-bar-background: var(--kp-color-white, #fff);
    --kp-card-background: var(--kp-color-white, #fff);
    --kp-font-color: rgba(0, 0, 0, 0.97);
    --kp-background-color: #ededed;
    --kp-background-color-rgb: 237, 237, 237;

    --easing: cubic-bezier(0.645, 0.045, 0.355, 1);
    --transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);
    --fz-xxs: 12px;
    --fz-xs: 13px;
    --fz-sm: 14px;
    --fz-md: 16px;
    --fz-lg: 18px;
    --fz-xl: 20px;
    --fz-xxl: 22px;
    --fz-heading: 32px;
    --border-radius: 16px;
    --navy-shadow: rgba(2, 12, 27, 0.7);
}

/*
   * Dark Colors
   * -------------------------------------------
   */

.dark-theme {
    --kp-color-success: #2fdf75;
    --kp-color-success-rgb: 47, 223, 117;
    --kp-color-success-contrast: #000000;
    --kp-color-success-contrast-rgb: 0, 0, 0;
    --kp-color-success-shade: #29c467;
    --kp-color-success-tint: #44e283;

    --kp-color-warning: #ffd534;
    --kp-color-warning-rgb: 255, 213, 52;
    --kp-color-warning-contrast: #000000;
    --kp-color-warning-contrast-rgb: 0, 0, 0;
    --kp-color-warning-shade: #e0bb2e;
    --kp-color-warning-tint: #ffd948;

    --kp-color-danger: #ff4961;
    --kp-color-danger-rgb: 255, 73, 97;
    --kp-color-danger-contrast: #ffffff;
    --kp-color-danger-contrast-rgb: 255, 255, 255;
    --kp-color-danger-shade: #e04055;
    --kp-color-danger-tint: #ff5b71;

    --kp-color-dark: #f4f5f8;
    --kp-color-dark-rgb: 244, 245, 248;
    --kp-color-dark-contrast: #000000;
    --kp-color-dark-contrast-rgb: 0, 0, 0;
    --kp-color-dark-shade: #dcdddf;
    --kp-color-dark-tint: #f5f6f9;

    --kp-color-medium: #989aa2;
    --kp-color-medium-rgb: 152, 154, 162;
    --kp-color-medium-contrast: #000000;
    --kp-color-medium-contrast-rgb: 0, 0, 0;
    --kp-color-medium-shade: #86888f;
    --kp-color-medium-tint: #a2a4ab;

    --kp-color-light: #222428;
    --kp-color-light-rgb: 34, 36, 40;
    --kp-color-light-contrast: #ffffff;
    --kp-color-light-contrast-rgb: 255, 255, 255;
    --kp-color-light-shade: #1e2023;
    --kp-color-light-tint: #383a3e;

    --kp-item-background: transparent;
    --kp-toolbar-background: #303030;
    --kp-tab-bar-background: #303030;
    --kp-card-background: #303030;
    --kp-font-color: #fff;
    --kp-background-color: #424242;
    --kp-background-color-rgb: 18, 18, 18;

    --kp-color-step-50: #1e1e1e;
    --kp-color-step-100: #2a2a2a;
    --kp-color-step-150: #363636;
    --kp-color-step-200: #414141;
    --kp-color-step-250: #4d4d4d;
    --kp-color-step-300: #595959;
    --kp-color-step-350: #656565;
    --kp-color-step-400: #717171;
    --kp-color-step-450: #7d7d7d;
    --kp-color-step-500: #898989;
    --kp-color-step-550: #949494;
    --kp-color-step-600: #a0a0a0;
    --kp-color-step-650: #acacac;
    --kp-color-step-700: #b8b8b8;
    --kp-color-step-750: #c4c4c4;
    --kp-color-step-800: #d0d0d0;
    --kp-color-step-850: #dbdbdb;
    --kp-color-step-900: #e7e7e7;
    --kp-color-step-950: #f3f3f3;
}
