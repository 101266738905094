@import url(./styles/themes/variable.css);
@import url(./styles/typography.css);
@import url(./styles/common.css);

body,
.dark-theme {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    background-color: var(--kp-background-color);
    counter-reset: section;
}

::-webkit-scrollbar {
    width: 0.6rem;
}

::-webkit-scrollbar-thumb {
    background-color: var(--kp-color-primary);
    /* border: 0.1rem solid var(--kp-background-color); */
    border-radius: 0.6rem;
}

::-webkit-scrollbar-track {
    background: var(--kp-background-color);
}
